import { configureStore } from '@reduxjs/toolkit';
import thunk from 'redux-thunk';
import { appReducer } from './reducers';

// Combina tus reducers
const rootReducer = {
  appState: appReducer,
  // otros reducers...
};

// Crea el store con configureStore
const store = configureStore({
  reducer: rootReducer,
//   middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(thunk),
});

// Define el tipo de RootState a partir del store
export type RootState = ReturnType<typeof store.getState>;

export default store;