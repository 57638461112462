import React from 'react';

// import { AppProvider } from './src/context/AppContext'; // Asegúrate de usar la ruta correcta
import { Provider } from 'react-redux';
import store from './src/store/store';
import "./src/styles/global.css"

export const wrapRootElement = ({ element }) => {
  return <Provider store={store}>{element}</Provider>;
};

