exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-game-over-tsx": () => import("./../../../src/pages/game-over.tsx" /* webpackChunkName: "component---src-pages-game-over-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-play-screen-tsx": () => import("./../../../src/pages/play-screen.tsx" /* webpackChunkName: "component---src-pages-play-screen-tsx" */),
  "component---src-pages-winner-tsx": () => import("./../../../src/pages/winner.tsx" /* webpackChunkName: "component---src-pages-winner-tsx" */),
  "component---src-template-question-tsx": () => import("./../../../src/template/question.tsx" /* webpackChunkName: "component---src-template-question-tsx" */)
}

