import { createSlice, PayloadAction } from '@reduxjs/toolkit';
// Define la forma del contexto
interface CategoryQuestion {
    id: number;
    option?: string,
    category: string;
    categoryInitial: string;
    categoryLogo: string;
    background: string;
    playLearnWin: string;
    lifesFooter: string;
    image: ImageType;
    style: StyleType;
    // questions: QuestionsType;
  }
  
  type StyleType = {
    backgroundColor: string;
  }
  type ImageType = {
    uri: string;
    offsetY: number;
  }

//   interface QuestionsType {
//     question: string;
//     answer: string;
//     options: string[];
//     image?: string;
//   }
  
interface YourState {
  categoryQuestion?: CategoryQuestion | null;
  points: number;
  winner: boolean;
  gameOver: boolean;
}

const initialState: YourState = {
    points: 0,
    winner: false,
    gameOver: false
};

const appSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    setCategory(state, action: PayloadAction<CategoryQuestion>) {
      state.categoryQuestion = action.payload;
    },
    winPoints(state) {
      state.points = state.points + 1;
    },
    clearPoints(state) {
      state.points = 0;
    },
    setWinner(state) {
      state.winner = true
    },
    setGameOver(state) {
      state.gameOver = true;
    },
    resetGame(state) {
      state.gameOver = false;
      state.winner = false;
      state.points = 0;
    },
  },
});

export const { setCategory, winPoints, clearPoints, setGameOver, setWinner, resetGame } = appSlice.actions;
export const appReducer = appSlice.reducer;